<template>
    <v-app>
        <v-main>
            <div class="fondo">
                <!-- **************************** -->
                <!-- El contenido                 -->
                <!-- **************************** -->
                <div class='pt-9 mt-8 '>
                    <!-- Ruta con transición-->
                    <router-view/>


                    <!-- **************************** -->
                    <!-- Ventana para iniciar sesión -->
                    <!-- **************************** -->
                    <login-window/>
                </div>
            </div>
        </v-main>
    </v-app>
</template>

<script>

import LoginWindows from '@/components/security/LoginWindow'
export default {
    name: 'App',
    components:{
        'login-window':LoginWindows,
    },
    data: () => ({
        drawer:true,
    }),
};

</script>

<style>
.fondo {
    min-width: 100%;
    min-height: 100%;
    background-image:url("/images/spikes.png");
    background-repeat:repeat;
}

</style>
















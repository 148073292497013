import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
//import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
        //iconfont: 'md',
    },
    theme: {

        themes: {
            light: {
                primary: '#285C4D',
                secondary: '#B38E5D',
            },
        },
    },

    lang: {
        locales: { es },
        current: 'es',
    },

});

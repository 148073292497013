export default{
    namespaced: true,

    state:{
        grafica:{},

        horarios:{
            mat: [
                '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00',
            ],
            ves: [
                '15:00', '16:00', '17:00', '18:00', '19:00', '20:00',
            ],
            noc: [
                '21:00', '22:00', '23:00', '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00',
            ],
        },

        coloresTuro:{
            mat:'#81D4FA',
            ves:'#A5D6A7',
            noc:'#EF9A9A',
        },

    },
    mutations:{
        //SET_LOGIN (state, ld){
        //    state.logedIn = true;
        //    state.username = ld.username;
        //    state.password = ld.password;

        //},
    },
    actions:{

        calculaEscala(context, params){

            let dat = params.dat
            let valor = null
            let texto = null
            let imagenRuta = ''
            let color = 'black'
            let entradaId = 0

            if (dat){
                entradaId = dat.id
            }

            switch (params.escala){
                case 'general':
                    if (dat){
                        switch(dat.valor){
                            case 1:
                                texto = 'Mejorado'
                                color = 'green'
                                break

                            case 2:
                                texto = 'Delicado'
                                color = 'orange'
                                break

                            case 3:
                                texto = 'Grave'
                                color = 'red'
                                break

                            case 4:
                                texto = 'Muy grave'
                                color = 'black'
                                break
                        }
                        valor = dat.valor
                    }
                break

                case 'reactividad':
                    if (dat){
                        switch(dat.valor){
                            case 1:
                                texto = 'Isocoria'
                                imagenRuta='/images/escalas/isocoria.jpg'
                                break

                            case 2:
                                texto = 'Midriasis'
                                imagenRuta='/images/escalas/midriasis.jpg'
                                break

                            case 3:
                                texto = 'Miosis'
                                imagenRuta='/images/escalas/miosis.jpg'
                                break

                            case 4:
                                texto = 'Anisocoria'
                                imagenRuta='/images/escalas/anisocoria.jpg'
                                break
                        }
                        valor = dat.valor
                    }
                    break

                case 'conciencia':
                    if (dat){
                        let con = JSON.parse(dat.valorString)
                        let esteValor = con.ocular + con.verbal + con.motor
                        valor = esteValor
                        if (esteValor <= 8){
                            texto = 'Trauma severo'
                            color = 'red'
                        }
                        if (esteValor > 8 && esteValor <= 12){
                            texto = 'Trauma moderado'
                            color = 'orange'
                        }
                        if (esteValor > 12){
                            texto = 'Trauma Leve'
                            color = 'green'
                        }
                    }
                    break

                case 'npass':
                    if (dat){
                        //console.log('----')
                        let con = JSON.parse(dat.valorString)
                        //console.log(con)
                        // {"motor":0,"verbal":0,"ocular":0,"sdg":3,"llanto":2,"comportamiento":-1,"facial":0,"muscular":2,"signos":-2,"correccionEdad":0}
                        let esteValor = con.sdg + con.llanto + con.comportamiento + con.facial + con.muscular + con.signos
                        //esteValor = esteValor - 2
                        //console.log(esteValor)
                        valor = esteValor
                        if (esteValor < 0){
                            texto = 'Sedado'
                            color = 'orange'
                        }
                        if (esteValor == 0){
                            texto = 'Normal'
                            color = 'green'
                        }
                        if (esteValor > 0){
                            texto = 'Con dolor'
                            color = 'red'
                        }
                    }
                    break

                case 'ramsay':
                    if(dat){
                        valor = dat.valor

                        if (dat.valor == 1){
                            imagenRuta='/images/escalas/ramsay-01.png'
                            texto='Despierto, ansioso'
                        }
                        if (dat.valor == 2){
                            imagenRuta='/images/escalas/ramsay-02.png'
                            texto='Despierto, tranquilo'
                        }
                        if (dat.valor == 3){
                            imagenRuta='/images/escalas/ramsay-03.png'
                            texto='Dormido a órdenes'
                        }
                        if (dat.valor == 4){
                            imagenRuta='/images/escalas/ramsay-04.png'
                            texto='Somnoliento'
                        }
                        if (dat.valor == 5){
                            imagenRuta='/images/escalas/ramsay-05.png'
                            texto='Dormido con respuesta'
                        }
                        if (dat.valor == 6){
                            imagenRuta='/images/escalas/ramsay-06.png'
                            texto='Profundamente dormido'
                        }
                    }
                    break

                case 'silverman':
                    if (dat){
                        let sil = JSON.parse(dat.valorString)
                        let esteValor = sil.torax + sil.ic + sil.xifo + sil.aleteo + sil.quejido
                        valor = esteValor

                        if (esteValor == 0){
                            texto = 'Sin dific.'
                            color = 'green'
                        }

                        if (esteValor > 0 && esteValor <= 3){
                            texto = 'Dific. leve'
                            color = 'blue'
                        }

                        if (esteValor > 3 && esteValor <= 6){
                            texto = 'Dific. moderada'
                            color = 'orange'
                        }
                        if (esteValor > 6){
                            texto = 'Dific. severa'
                            color = 'red'
                        }
                    }
                    break

                case 'caidas':
                    if (dat){
                        let valorDat = 0
                        let con = dat.valorString.split(',')
                        con.forEach(c2 => {
                            valorDat = valorDat + parseInt(c2.split('-')[1])
                        });
                        valor = valorDat
                        if (valorDat <= 1){
                            texto = 'Bajo'
                            color = 'green'
                        }
                        if (valorDat > 1 && valorDat <= 3){
                            texto = 'Mediano'
                            color = 'orange'
                        }
                        if (valorDat > 3){
                            texto = 'Alto'
                            color = 'red'
                        }
                    }
                    break

                case 'upp':
                    if (dat){
                        let con = JSON.parse(dat.valorString)
                        let valDat = con.percepcion + con.humedad + con.actividad+con.movilidad+con.nutricion+con.roce
                        valor = valDat
                        if (valDat < 12){
                            texto = 'Alto'
                            color = 'red'
                        }
                        if (valDat >=12  && valDat <= 14){
                            texto = 'Moderado'
                            color = 'orange'
                        }
                        if (valDat > 14){
                            texto = 'Bajo'
                            color = 'green'
                        }
                    } 
                    break

                case 'dolor':
                    if(dat){
                        valor = dat.valor
                        if (dat.valor == 0){
                            texto='Sin'
                            imagenRuta = '/images/escalas/dolor-1.png'
                        }
                        if (dat.valor >0 && dat.valor <=2){
                            texto='Poco'
                            imagenRuta='/images/escalas/dolor-2.png'
                        }
                        if (dat.valor >2 && dat.valor <=4){
                            texto='Moderado'
                            imagenRuta='/images/escalas/dolor-3.png'
                        }
                        if (dat.valor >4 && dat.valor <=6){
                            texto='Fuerte'
                            imagenRuta='/images/escalas/dolor-4.png'
                        }
                        if (dat.valor >6 && dat.valor <=8){
                            texto='Muy fuerte'
                            imagenRuta='/images/escalas/dolor-4.png'
                        }
                        if (dat.valor >8){
                            texto='Insoportable'
                            imagenRuta='/images/escalas/dolor-6.png'
                        }
                    }
                    break

                case 'dolorPipp':
                    if (dat){
                        let con = JSON.parse(dat.valorString)
                        let esteValor = con.edad + con.comportamiento + con.fc + con.sat + con.cejas + con.ojos + con.labio
                        valor = esteValor
                        if (esteValor >= 12){
                            texto = 'Intenso'
                            color = 'red'
                        }
                        if (esteValor > 6 && esteValor < 12){
                            texto = 'Moderado'
                            color = 'orange'
                        }
                        if (esteValor <=6){
                            texto = 'Leve o sin'
                            color = 'green'
                        }
                    } 
                    break

                case 'flebitis':
                    if (dat){
                        valor = dat.valor
                        switch(dat.valor){
                            case 0:
                                texto = 'Sin'
                                color = 'green'
                                break

                            case 1:
                                texto = 'Posible'
                                color = 'orange'
                                break

                            case 2:
                                texto = 'Inicio'
                                color = 'red'
                                break

                            case 3:
                                texto = 'Media'
                                color = 'red'
                                break

                            case 4:
                                texto = 'Avanzado'
                                color = 'red'
                                break

                            case 5:
                                texto = 'Tromboflebitis'
                                color = 'red'
                                break
                        }
                    }
                    break
                }
                return ({valor:valor, text:texto, color:color, imagen:imagenRuta, id:entradaId})
        },

        calculaTurno(context, hora){
            let turno = ''
            let v1 = hora.split(':')
            let hr = parseInt(v1[0], 10);

            if (hr >=8 && hr < 14)
                turno = 'mat'
            if (hr >=14 && hr < 20)
                turno = 'ves'
            if (hr >=20 && hr <= 23)
                turno = 'noc'
            if (hr >=0 && hr < 8)
                turno = 'noc'
            return turno
        },

        async guardaSomato(context, params){
            let ret = false
            //console.log(context)
            let sg = await this.$http({
                url:'/enfermeria/guardaSignos',
                method:'POST',
                data: params
            })

            if (sg.data.estado == true){
                ret = true
            } else {
                this.Swal.fire({
                    icon: 'warning',
                    title: 'No se puede guardar',
                    text: 'Verifique los datos e intente de nuevo',
                })
                ret = false
            }
            return ret
        },

        async getSignosVitales(context, params){
            try{
                // Obtiene todos los signos vitales
                let req = await this.$http({
                    method:"GET",
                    url:'/enfermeria/cargaSignos',
                    params:{
                        fecha:params.estaFecha,
                        internamiento: params.internamiento
                    }
                })

                // Filtra todos por turno
                let datosTodos = req.data.lista
                let dt=[]
                context.state.horarios[params.turno].forEach((ent)=>{
                    let estaEntradaHora = ent.split(':')[0]
                    datosTodos.filter(entrada=>{
                        if (entrada.hora.split(':')[0] == estaEntradaHora){
                            dt.push(entrada)
                        }
                    })
                })

                // Obtiene las listas de los signos que tiene esta entrada
                let mapa = dt.map((itm)=>{
                    return itm.signo
                })
                let listaSignos = [
                    ...new Set(mapa)
                ]

                // Ordena los signos por horas
                let signosHoras={}
                context.state.horarios[params.turno].forEach((ent)=>{
                    let estaEntradaHora = ent.split(':')[0]
                    let entr = dt.filter(entrada=>{
                        if (entrada.hora.split(':')[0] == estaEntradaHora){
                            return entrada
                        }
                    })
                    signosHoras[estaEntradaHora] = entr
                })
                return {
                    todos: dt,
                    listaSignos: listaSignos,
                    signosHoras: signosHoras
                }
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async medicamentosAplicados(context, params){
            try{
                let req = await this.$http({
                    method:"GET",
                    url:'/enfermeria/listMedicamentosTodos',
                    params:{
                        fecha:params.estaFecha,
                        internamiento: params.internamiento,
                        activo:1
                    }
                })
                // Todos los medicamentos
                let todos = req.data.rootApp
                let aplicadosTodos=[]
                todos.map(itm=>itm.aplicados).forEach(itm=>{
                    itm.forEach(itm2=>{
                        aplicadosTodos.push(itm2)
                    })
                })
                //Filtra todos los medicamentos por turno
                let aplicadosTurno=aplicadosTodos.filter(itm=>itm.turno==params.turno)

                // Datos generales de los medicamentos del turno
                let mapa = aplicadosTurno.map((itm)=>{
                    return itm.medicamento
                })
                let listaMeds = [
                    ...new Set(mapa)
                ]

                // Filtra solo los medicamentos que se aplicaron en este turno
                let medicamentosTurno=[]
                listaMeds.forEach(itm3=>{
                    medicamentosTurno.push(todos.filter(itm4=>itm4.id == itm3)[0])
                })
                return {
                    medicamentos: medicamentosTurno,
                    aplicados: aplicadosTurno,
                }
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async getSolucionesTodas(context, params){
            try{
                //this.fechaActual=true
                let cs = await this.$http({
                    method:'GET',
                    url:'/enfermeria/listSolTodas',
                    params:{
                        internamiento: params.internamiento,
                        fecha: params.estaFecha,
                        turno: params.turno
                    }
                })
                //this.fechaActual=false

                return {todas: cs.data.filter(itm=>itm.duracion >0)}
            } catch(err){
                //this.fechaActual=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async getBalanceLiq(context, params){
            try{

                let io = await this.$http({
                    url:'/enfermeria/listIngresosEgresos',
                    method:'GET',
                    params:{
                        internamiento: params.internamiento,
                        fecha: params.estaFecha,
                        turno: params.turno
                    }
                })
                //console.log(io.data.todosTurnos)
                return ({
                    ingresos:io.data.ingresos,
                    egresos:io.data.egresos,
                    fecal:io.data.fecal,
                    urinario:io.data.urinario,
                    totalIngresos:io.data.totalIngresos,
                    totalEgresos:io.data.totalEgresos,
                    todosTurnos: io.data.todosTurnos
                })
            } catch(err){
                //this.fechaActual=false
                this.$store.dispatch('security/revisaError',err)
            }
        },

        async datosGraficasSomato(context, params){
            let temp=[]
            let fc=[]
            let series = []
            let opciones = {}

            try{
                // Obtiene todos los signos vitales
                let req = await this.$http({
                    method:"GET",
                    url:'/enfermeria/cargaSignos',
                    params:{
                        fecha:params.estaFecha,
                        internamiento: params.internamiento
                    }
                })

                let listSV = req.data.lista

                let fcArray=[]
                let fcData = listSV.filter(ent=>ent.signo=='fc')
                let tempArray = []
                let tempData = listSV.filter(ent=>ent.signo=='temp')

                //console.log(fcData)

                context.state.horarios[params.turno].forEach((hr)=>{

                    let estaHora = parseInt(hr.split(":")[0])

                    fcData.forEach((ent) => {

                        let fechaEntrada = this.moment(ent.fechaRegistro, 'DD/MM/YYYY HH:mm')
                        let hora = fechaEntrada.format('H')
                        if (hora>=0 && hora < 8){
                            fechaEntrada == fechaEntrada.add(1, "d")
                        }

                        if (estaHora == hora){
                            fcArray.push([
                                fechaEntrada.toDate().getTime(),
                                ent.valor
                            ])
                        }

                    })
                    tempData.forEach((ent) => {

                        let fechaEntrada = this.moment(ent.fechaRegistro, 'DD/MM/YYYY HH:mm')
                        let hora = fechaEntrada.format('H')
                        if (hora>=0 && hora < 8){
                            fechaEntrada == fechaEntrada.add(1, "d")
                        }

                        if (estaHora == hora){
                            tempArray.push([
                                fechaEntrada.toDate().getTime(),
                                ent.valor
                            ])
                        }

                    })
                })



                series=[
                    {
                        name: "Temperatura",
                        data: tempArray,
                        //data:this.temp
                    },
                    {
                        name: "Frecuencia cardíaca",
                        //data: []
                        data: fcArray
                        //data:this.fc
                    }
                ]

                opciones = {
                    legend: {
                        show:false,
                    },

                    noData: {
                        text: 'No hay datos para esta guardia',
                        style: {
                            color: '#F00',
                            fontSize: '8px',
                        }
                    },

                    chart: {
                        type: 'line',
                        zoom: {
                            enabled: false
                        },
                        toolbar:{
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        offsetY: -3,
                        style:{
                            fontSize:"7px",
                            fontWeight: '400'
                        },
                        background: {
                            enabled: false,
                            //padding: 1,
                        }
                    },

                    colors: ['#F00', '#00F'],

                    markers: {
                        size: 2,
                        strokeWidth:0,
                        hover: {
                            sizeOffset: 1
                        },
                        shape:['square']
                    },

                    stroke: {
                        show: true,
                        dashArray: [0, 2],
                        width:[2,2],
                        curve: 'straight',
                    },

                    grid: {
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },

                    },

                    xaxis: {
                        crosshairs: {
                            show:false,
                        },
                        tooltip: {
                            enabled: false,
                        },

                        axisBorder: {
                            show: false,
                        },

                        type:'datetime',
                        min: this.moment(params.estaFecha+' '+context.state.horarios[params.turno][0], 'DD-MM-YYYY HH:mm').toDate().getTime(),
                        max: this.moment(params.estaFecha+' '+context.state.horarios[params.turno][0], 'DD-MM-YYYY HH:mm').add(context.state.horarios[params.turno].length,'h').toDate().getTime(),
                        tickAmount: context.state.horarios[params.turno].length,

                        axisTicks: {
                            show: false,
                        },

                        labels:{
                            offsetY: -4,
                            datetimeUTC: false,
                            formatter: (value) => {
                                return this.moment(value).format('HH')
                            },
                            style:{
                                fontSize: '8px',
                            },
                        },
                    },

                    yaxis: [
                        {
                            seriesName:"Temp",
                            opposite: true,
                            min: 35,
                            max: 42,

                            axisTicks: {
                                show: false
                            },

                            labels: {
                                style: {
                                    //color: "#EF9A9A",
                                    fontSize: '8px',
                                }
                            },
                            title:{
                                text:"Temp",
                                style:{
                                    fontSize: '7px',
                                },
                            },
                        },

                        {
                            seriesName:"F.C.",
                            min: 0,
                            max: 200,
                            axisTicks: {
                                show: false
                            },

                            labels: {
                                style: {
                                    //color: "#90CAF9",
                                    fontSize: '8px',
                                }
                            },
                            title:{
                                text:"F.C.",
                                style:{
                                    fontSize: '7px',
                                },
                            },

                        }
                    ],
                    tooltip: {
                        enabled: false,
                    },

                }
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }

            return({
                temp:temp,
                fc:fc,
                options: opciones,
                series: series,
            })
        },

        async getDatosEscalas(context, params){
            try{
                // Obtiene todos los signos vitales
                let req = await this.$http({
                    method:"GET",
                    url:'/enfermeria/cargaEscalas',
                    params:{
                        fecha:params.estaFecha,
                        internamiento: params.internamiento,
                        turno: params.turno
                    }
                })
                return({todas:req.data.root})


            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        async getDatosValoraciones(context, params){
            try{
                // Obtiene todos los signos vitales
                let req = await this.$http({
                    method:"GET",
                    url:'/enfermeria/listValoraciones',
                    params:{
                        fecha:params.estaFecha,
                        internamiento: params.internamiento,
                        turno: params.turno
                    }
                })
                return(req.data.root)


            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        async getDiagnosticos(context, params){
            try{
                // Obtiene todos los signos vitales
                let req = await this.$http({
                    method:"GET",
                    url:'/enfermeria/cargaDiagnosticos',
                    params:{
                        fecha:params.estaFecha,
                        internamiento: params.internamiento,
                        turno: params.turno
                    }
                })
                return({
                    diagnosticos:req.data.root,
                    usuario: req.data.usuario
                })


            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        async getResultados(context, params){
            try{
                // Obtiene todos los signos vitales
                let req = await this.$http({
                    method:"GET",
                    url:'/enfermeria/listResultadosTodos',
                    params:{
                        fecha:params.estaFecha,
                        internamiento: params.internamiento,
                        turno: params.turno
                    }
                })
                return({resultados:req.data.root})


            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        async getNutri(context, params){
            try{
                // Obtiene todos los signos vitales
                let req = await this.$http({
                    method:"GET",
                    url:'/enfermeria/listNutricion',
                    params:{
                        fecha:params.estaFecha,
                        internamiento: params.internamiento,
                        turno: params.turno
                    }
                })
                return({nutricion:req.data.root})


            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        async getIntervenciones(context, params){
            try{
                // Obtiene todos los signos vitales
                let req = await this.$http({
                    method:"GET",
                    url:'/enfermeria/listIntervenciones',
                    params:{
                        fecha:params.estaFecha,
                        internamiento: params.internamiento,
                        turno: params.turno
                    }
                })
                return(req.data.root)


            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        async getLab(context, params){
            let fechaDe = this.moment(params.estaFecha+' '+context.state.horarios[params.turno][0], 'DD-MM-YYYY HH:mm')
            let fechaA = this.moment(params.estaFecha+' '+context.state.horarios[params.turno][0], 'DD-MM-YYYY HH:mm').add(context.state.horarios[params.turno].length, 'hours')

            try{
                let req = await this.$http({
                    method:"GET",
                    url:'/laboratorio/listEstudios',
                    params:{
                        fechaDe:this.moment(fechaDe).format('DD-MM-YYYY HH:mm'),
                        fechaA:this.moment(fechaA).format('DD-MM-YYYY HH:mm'),
                        internamiento: params.internamiento,
                        internamientos: params.internamiento,
                        //turno: params.turno
                    }
                })
                return({laboratorio:req.data.root})


            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        async getRx(context, params){
            let fechaDe = this.moment(params.estaFecha+' '+context.state.horarios[params.turno][0], 'DD-MM-YYYY HH:mm')
            let fechaA = this.moment(params.estaFecha+' '+context.state.horarios[params.turno][0], 'DD-MM-YYYY HH:mm').add(context.state.horarios[params.turno].length, 'hours')

            try{
                let req = await this.$http({
                    method:"GET",
                    url:'/imagen/listEstudios',
                    params:{
                        fechaDe:this.moment(fechaDe).format('DD-MM-YYYY HH:mm'),
                        fechaA:this.moment(fechaA).format('DD-MM-YYYY HH:mm'),
                        internamiento: params.internamiento,
                        internamientos: params.internamiento,

                        //turno: params.turno
                    }
                })
                return({rx:req.data.root})


            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        async getPlanAlta(context, params){
            try{
                let req = await this.$http({
                    method:"GET",
                    url:'/enfermeria/listPlanAlta',
                    params:{
                        //fecha:params.estaFecha,
                        internamiento: params.internamiento,
                        //turno: params.turno
                    }
                })
                return(req.data.root)


            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async getValoracion(context, params){
            try{
                let req = await this.$http({
                    method:"GET",
                    url:'/enfermeria/listAntecedentes',
                    params:{
                        //fecha:params.estaFecha,
                        internamiento: params.internamiento,
                        //turno: params.turno
                    }
                })
                return(req.data)


            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async getValoracionPatologicos(context, params){
            try{
                let req = await this.$http({
                    method:"GET",
                    url:'/enfermeria/listAntecedentesPatologicos',
                    params:{
                        //fecha:params.estaFecha,
                        internamiento: params.internamiento,
                        //turno: params.turno
                    }
                })
                return(req.data.root)


            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },


    },
    getters:{

    }
}
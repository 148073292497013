<template>
    <v-dialog
      v-model="loginDialog"
      width="350"
      persistent
      
    >
        <v-card
            class="mx-auto"
            max-width="850"
            elevation=2
        >
      
            <v-img
                class="white--text"
                src="/images/acceso.jpg"
                lazy-src="images/acceso.jpg"
                height="180"
            >
                <v-card-title >
                    Iniciar sesión
                </v-card-title>
            </v-img>

            <v-form
                class="mt-5"
                @submit="doLogin"
                onSubmit="return false;"
                v-model="validaLogin"
                ref="formLogin"
            >
                <v-card-text>
                    <v-text-field
                        v-model="loginDatos.username"
                        :rules="requerido"
                        filled
                        rounded
                        label="Usuario"
                        @input="mensageLogin=''"
                    ></v-text-field>
                    <v-text-field
                        v-model="loginDatos.password"
                        :rules="requerido"
                        filled
                        rounded
                        type="password"
                        label="Contraseña"
                        @input="mensageLogin=''"
                    ></v-text-field>

                    <v-expand-transition :v-if="muestraError">
                        <v-alert
                            color="error"
                            v-if="muestraError"
                            dense
                            text
                            border="left"
                        >
                            <span class="caption">
                                {{mensageLogin}}
                            </span>
                        </v-alert>
                    </v-expand-transition>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="error"
                        text
                        @click="cancelLogin"
                    >
                        Cancelar
                    </v-btn>
                    
                    <v-btn
                        :disabled="!validaLogin"
                        color="success"
                        
                        type='submit'
                        :loading="loadingLogin"
                        
                    >
                        Iniciar sesión
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
//import Swal from "sweetalert2"
//import 'sweetalert2/dist/sweetalert2.min.css'

export default {
    props:{
        //loginDialog: Boolean
    },
    data: () => ({
        loginDatos:{
            username:'',
            password:'',
        },
        muestraError:false,
        loadingLogin:false,
        mensageLogin:'',
        validaLogin:false,
        requerido: [
            v => !!v || 'Requerido',
        ],
    }),
    computed:{
        loginDialog:{
            get: function(){
                return this.$store.getters['security/ventanaLogin']
            },
            set: function(val){
                return this.$store.commit('security/ABRE_VENTANA_LOGIN', val)
            }
        }
    },
    methods:{
        cancelLogin(){
            this.mensageLogin = ''
            this.muestraError=false
            this.$refs.formLogin.reset()
            this.loginDialog= false

            //CIERRA_SESION
            this.$store.commit('security/CIERRA_SESION');
            this.$router.push({path:'/'})
            //this.$emit('update:loginDialog', false)
        },
        async doLogin(){
            this.muestraError=false
            this.loadingLogin=true
            this.mensageLogin = ''
            try {
                let resp = await this.$http({
                url: 'login',
                method: 'POST',
                data: this.loginDatos,
            })
            this.$store.commit('security/GUARDA_DATOS_LOGIN', resp);
            
            this.$refs.formLogin.reset()
            
            this.loadingLogin=false
            this.loginDialog= false

            }catch(err) {
                this.loadingLogin=false
                this.muestraError=true
                console.log(err)
                if (err.message == "Network Error"){
                    this.mensageLogin = 'No es posible establecer comunicación con el servidor'
                    
                } else {
                    switch(err.response.status){
                        
                        case 401:
                            //context.state.ventanaLogin = true
                            this.mensageLogin = 'Nombre de usuario o contraseñas incorrectas'
                        break

                        case 403:
                            this.mensageLogin = 'No tiene los permiso para esta acción'
                            
                        break
                        
                        default:
                            this.mensageLogin = 'No es posible establecer ejecutar la instrucción. Error '+err.response.status
                            
                        break
                    }
                        
                }
            }
        }
    },
}
</script>

<style>

</style>
export default{
    namespaced: true,

    state:{
        areasCen:[
            {area:13, camasServicio:0, usadas:0, areaNombre:''},
            {area:14, camasServicio:0, usadas:0, areaNombre:''},
            {area:5, camasServicio:0, usadas:0, areaNombre:''},
            {area:6, camasServicio:0, usadas:0, areaNombre:''},
            {area:7, camasServicio:0, usadas:0, areaNombre:''},
            {area:8, camasServicio:0, usadas:0, areaNombre:''},
        ],
        areasNoCen:[
            {area:1, camasServicio:0, usadas:0, areaNombre:''},
            {area:2, camasServicio:0, usadas:0, areaNombre:''},
            {area:3, camasServicio:0, usadas:0, areaNombre:''},
            {area:9, camasServicio:0, usadas:0, areaNombre:''},
            {area:11, camasServicio:0, usadas:0, areaNombre:''},
            {area:10, camasServicio:0, usadas:0, areaNombre:''},
        ]

            
            
    },
    mutations:{
        //SET_LOGIN (state, ld){
        //    state.logedIn = true;
        //    state.username = ld.username;
        //    state.password = ld.password;

        //},
    },
    actions:{
        //logIn(context, parametros){
        //    context.commit('SET_LOGIN', parametros)
        //}
    },
    getters:{
        areasCenList(state){
            return state.areasCen;
        },
        areasNoCenList(state){
            return state.areasNoCen;
        },
    }
}
import Vue from 'vue'
import Vuex from 'vuex'

import security from './security'
import hospital from './hospital/hospital'
import enfermeria from './hospital/enfermeria'
import catalogos from './catalogos'
import calidad from './calidad/calidad'
import quirofano from './quirofano/quirofano'
// import obras from './obras/obras'
// import cajas from './admin/cajas/cajas'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    security,
    hospital,
    enfermeria,
    catalogos,
    calidad,
    quirofano,
    // obras,
    // cajas,
  }
})

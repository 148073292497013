export default{
    namespaced: true,

    state:{
        datosBusqueda:{},
        fechas:[],
        fechaArr:[],
        optionsTableList:{},

        statusCirugia:{
            1:{
                color:'purple',
                text:'Solicitada',
            },
            2:{
                color:'blue',
                text:'Agendada',
            },
            3:{
                color:'orange',
                text:'En sala',
            },
            4:{
                color:'orange',
                text:'Recuper',
            },
            5:{
                color:'green',
                text:'Realizada',
            },
            90:{
                color:'red',
                text:'Reagenda',
            },
            99:{
                color:'black',
                text:'Cancelada',
            },
        },

    },
    mutations:{
        ACTUALIZA_PARAMS(state,params){
            state.datosBusqueda = params
        },
        ACTUALIZA_FECHAS(state,params){
            state.fechas = params
        },
        ACTUALIZA_FECHAARR(state,params){
            state.fechaArr = params
        },
        ACTUALIZA_OPTIONS_LIST(state, params){
            state.optionsTableList=params
        },
        //SET_LOGIN (state, ld){
        //    state.logedIn = true;
        //    state.username = ld.username;
        //    state.password = ld.password;

        //},
    },
    actions:{
        //logIn(context, parametros){
        //    context.commit('SET_LOGIN', parametros)
        //}
    },
    getters:{

    }
}
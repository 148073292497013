import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/dash',
        name: 'homeDash',
        component: () => import('@/views/Dash.vue'),
    },
    {
        path: '/dashboard',
        component: () => import('../views/Main.vue'),
        children:[
            {
                path:'/',
                name: 'dashboardPrincipal',
                component: () => import('../views/principal/Main.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_PACIENTES','ROLE_RH_USUARIO','ROLE_RH_ADMIN','ROLE_RH_REPORTES'],
                },
            }
        ]
    },
    {
        path:'/hospital',
        component: () => import('../views/Main.vue'),
        children:[
            {
                path:'ocupacion/:id',
                name:'hospitalOcupacion',
                component: () => import('../views/hospital/OcupacionArea.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_PACIENTES'],
                },
            },
            {
                path:'interna/:id',
                name:'hospitalInternamiento',
                component: () => import('../views/hospital/Internamiento.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_PACIENTES'],
                },
            },
        ]
    },
    {
        path:'/urgencias',
        component: () => import('../views/Main.vue'),
        children:[
            {
                path:'covid',
                name:'urgenciasCovid',
                component: () => import('../views/urgencias/Covid.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_URGENCIAS_COVID'],
                },
            },
            {
                path:'detalleCovid/:id',
                name:'detalleCovid',
                component: () => import('../views/urgencias/DetallesCovid.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_URGENCIAS_COVID'],
                },
            },
        ]
    },
    {
        path: '/somato',
        component: () => import('../views/Main.vue'),
        children:[
            {
                path:'/',
                name: 'somatoPrincipal',
                component: () => import('../views/somato/Main.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_PACIENTES'],
                },
            },
        ]
    },
    {
        path: '/consulta',
        component: () => import('@/views/Main.vue'),
        children:[
            {
                path:'/',
                //name: 'consultaMain',
                component: () => import('@/views/consulta/ConsultaMain.vue'),
                meta: {
                    requiresAuth: false,
                    //roles:['ROLE_PACIENTES'],
                },
                children:[
                    {
                        path:'/',
                        name: 'consultaHome',
                        component: () => import('@/views/consulta/ConsultaHome.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_CONSULTA'],
                        },
                    },
                    {
                        path:'agenda',
                        name: 'consultaAgenda',
                        component: () => import('@/views/consulta/ConsultaListado.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_CONSULTA'],
                        },
                    },
                ]
            },
        ]
    },
    {
        path: '/admin',
        component: () => import('../views/Main.vue'),
        children:[
            {
                path:'/',
                name: 'adminPrincipal',
                component: () => import('../views/admin/Main.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN'],
                },
            },
            {
                path:'bb',
                name: 'bbPrincipal',
                component: () => import('../views/admin/bb/BbMain.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN'],
                },
            },
            {
                path:'usuarios',
                name: 'adminUsuarios',
                component: () => import('../views/admin/usuarios.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN'],
                },
            },
            {
                path:'borrados',
                name: 'adminBorrados',
                component: () => import('@/views/admin/borrados/Borrados.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN'],
                },
            },
            {
                path:'catalogos',
                name: 'adminCatalogos',
                component: () => import('../views/admin/catalogos.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN'],
                },
            },
            {
                path:'joincip',
                name: 'joinCip',
                component: () => import('../views/admin/joincip/JoinCip.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN'],
                },
            },
        ]
    },
    {
        path: '/quirofano',
        component: () => import('../views/Main.vue'),
        children:[
            {
                path:'/',
                name: 'quirofanoMain',
                component: () => import('../views/quirofano/QuirofanoListaCirugias.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_CIRUGIA','ROLE_CIRUGIA_ENF', 'ROLE_CIRUGIA_MED'],
                },
            },
            {
                path:':id',
                name: 'quirofanoDetalleId',
                component: () => import('../views/quirofano/QuirofanoDetalleCirugia.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_CIRUGIA','ROLE_CIRUGIA_ENF', 'ROLE_CIRUGIA_MED'],
                },
            },
        ]
    },
    {
        path: '/rhumanos',
        component: () => import('../views/Main.vue'),
        children:[
            {
                path:'/',
                name: 'menuRh',
                component: () => import('../views/rhumanos/menurh.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_RH_USUARIO','ROLE_RH_ADMIN','ROLE_RH_REPORTES'],
                },
            },
            {
                path:'incidencias',
                name: 'incidenciasRh',
                component: () => import('../views/rhumanos/incidencias.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_RH_USUARIO','ROLE_RH_ADMIN','ROLE_RH_REPORTES'],
                },
            },
            {
                path:'reportes',
                name: 'reportesRh',
                component: () => import('../views/rhumanos/reportes.vue'),
                meta: {
                    roles:['ROLE_ADMIN', 'ROLE_RH_USUARIO','ROLE_RH_ADMIN','ROLE_RH_REPORTES'],
                    requiresAuth: true,
                },
            },
            {
                path:'trabajadores',
                name: 'trabajadoresRh',
                component: () => import('../views/rhumanos/trabajadores.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_RH_USUARIO','ROLE_RH_ADMIN','ROLE_RH_REPORTES'],
                },
            },
        ]
    },
    {

        path: '/estadistica',
        component: () => import('@/views/Main.vue'),
        children:[
            {
                path:'/',
                name: 'estadisticaPrincipal',
                component: () => import('@/views/estadistica/EstadisticaPrincipal.vue'),
                meta: {
                    roles:['ROLE_ADMIN', 'ROLE_ESTADISTICA','ROLE_ESTADISTICA_ADMIN'],
                    requiresAuth: true,
                },
                children:[
                    {
                        path:'indicasconsulta',
                        name: 'indicasConsulta',
                        component: () => import('@/views/estadistica/indicas/EsperaConsulta.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN', 'ROLE_ESTADISTICA','ROLE_ESTADISTICA_ADMIN'],
                        },
                    },
                    {
                        path:'indicasurgencias',
                        name: 'indicasUrgencias',
                        component: () => import('@/views/estadistica/indicas/EsperaUrgencias.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN', 'ROLE_ESTADISTICA','ROLE_ESTADISTICA_ADMIN'],
                        },
                    },
                    {
                        path:'consutasis',
                        name: 'consultaSis',
                        component: () => import('@/views/estadistica/consulta/ConsultaSis.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN', 'ROLE_ESTADISTICA','ROLE_ESTADISTICA_ADMIN'],
                        },
                    },
                    {
                        path:'estudiosis',
                        name: 'estudioSis',
                        component: () => import('@/views/estadistica/consulta/EstudioSis.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN', 'ROLE_ESTADISTICA','ROLE_ESTADISTICA_ADMIN'],
                        },
                    },
                    {
                        path:'estadisticaPacientes',
                        component: () => import('@/views/estadistica/reportes/EstadisticaPacientes.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN', 'ROLE_ESTADISTICA','ROLE_ESTADISTICA_ADMIN'],
                        },
                        children:[
                            {
                                path:'/',
                                name: 'estadisticaPacientes',
                                component: () => import('@/views/estadistica/reportes/PacientesListado.vue'),
                                meta: {
                                    requiresAuth: true,
                                    roles:['ROLE_ADMIN', 'ROLE_ESTADISTICA','ROLE_ESTADISTICA_ADMIN'],
                                }
                            },
                            {
                                path:'listado',
                                name: 'estadisticaListadoPacientes',
                                component: () => import('@/views/estadistica/reportes/PacientesListado.vue'),
                                meta: {
                                    requiresAuth: true,
                                    roles:['ROLE_ADMIN', 'ROLE_ESTADISTICA','ROLE_ESTADISTICA_ADMIN'],
                                }
                            },
                            {
                                path:'esperaConsulta',
                                name: 'estadisticaEsperaConsulta',
                                component: () => import('@/views/estadistica/reportes/PacientesEsperaConsulta.vue'),
                                meta: {
                                    requiresAuth: true,
                                    roles:['ROLE_ADMIN', 'ROLE_ESTADISTICA','ROLE_ESTADISTICA_ADMIN'],
                                }
                            },
                            {
                                path:'esperaUrgencias',
                                name: 'estadisticaEsperaUrgencias',
                                component: () => import('@/views/estadistica/reportes/PacientesEsperaUrgencias.vue'),
                                meta: {
                                    requiresAuth: true,
                                    roles:['ROLE_ADMIN', 'ROLE_ESTADISTICA','ROLE_ESTADISTICA_ADMIN'],
                                }
                            },
                            {
                                path:'cirugias',
                                name: 'estadisticacirugias',
                                component: () => import('@/views/estadistica/reportes/PacientesReporteCirugias.vue'),
                                meta: {
                                    requiresAuth: true,
                                    roles:['ROLE_ADMIN', 'ROLE_ESTADISTICA','ROLE_ESTADISTICA_ADMIN'],
                                }
                            },
                        ],
                    },
                ]
            },
        ]
    },
    {
        path: '/referencias',
        component: () => import('@/views/Main.vue'),
        children:[
            {
                path:'/',
                name: 'referenciasPrincipal',
                component: () => import('@/views/referencias/ReferenciasPrincipal.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_REFERENCIAS'],
                }
            },
            {
                path:'nueva',
                name: 'referenciaNueva',
                component: () => import('@/views/referencias/ReferenciaNueva.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_REFERENCIAS'],
                }
            },
            {
                path:'ref/:folio',
                name: 'referenciaDetalle',
                component: () => import('@/views/referencias/ReferenciaDetalle.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_REFERENCIAS'],
                }
            },
        ]
    },
    {
        path: '/farmacia',
        component: () => import('@/views/Main.vue'),
        children:[
            {
                path:'/',
                name: 'farmacia',
                component: () => import('@/views/farmacia/Farmacia.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_FARMACIA_EXIST_MED','ROLE_FARMACIA_EXISTENCIAS'],
                }
            },
            {
                path:'cbasico',
                name: 'farmaciaCBasico',
                component: () => import('@/views/farmacia/FarmaciaCbasico.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_PACIENTES'],
                }
            },
            {
                path:'recetas',
                name: 'farmaciaRecetas',
                component: () => import('@/views/farmacia/FarmaciaRecetas.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_PACIENTES'],
                }
            },
        ]
    },
    {
        path: '/tsocial',
        component: () => import('@/views/Main.vue'),
        children:[
            {
                path:'/',
                name: 'trabajosocial',
                component: () => import('@/views/tsocial/Tsocial.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_TRABAJO_SOCIAL'],
                }
            },
        ]
    },
    {
        path: '/calidad',
        component: () => import('@/views/Main.vue'),
        children:[
            {
                path:'/',
                name: 'eventosadversos',
                component: () => import('@/views/calidad/adversos/EventosAdversos.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_CALIDAD', 'ROLE_CALIDAD_ADMIN'],
                }
            },
            {
                path:'evento/:id',
                name:'detalleEvento',
                component: () => import('@/views/calidad/adversos/DetalleEvento.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN', 'ROLE_CALIDAD', 'ROLE_CALIDAD_ADMIN'],
                },
            },
        ]
    },
    {
        path: '/GPC',
        component: () => import('@/views/Main.vue'),
        children:[
            {
                path: '/',
                name: 'gpc',
                component: () => import('@/views/Gpc.vue'),
                meta: {
                    requiresAuth: false,
                    //roles:['ROLE_ADMIN', 'ROLE_CALIDAD', 'ROLE_CALIDAD_ADMIN'],
                },
            },
        ]
    },

    {
        path: '/archivos',
        component: () => import('@/views/Main.vue'),
        children:[
            {
                path:'/',
                name: 'archivosList',
                component: () => import('@/views/admin/archivos/Archivos.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_PLANEACION', 'ROLE_PLANEACION_ADMIN'],
                }
            },
        ]
    },
    {
        path: '/archivo',
        component: () => import('@/views/Main.vue'),
        children:[
            {
                path:'/',
                component: () => import('@/views/archivo/ArchivoMain.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_PACIENTES_GUARDA', 'ROLE_ADMIN', 'ROLE_ADMISION', 'ROLE_PACIENTES'],
                },
                children:[
                    {
                        path:'/',
                        name: 'archivoComunicados',
                        component: () => import('@/views/archivo/ArchivoComunicados.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_PACIENTES_GUARDA', 'ROLE_ADMIN'],
                        },
                    },
                    {
                        path:'pacientes',
                        name: 'archivoPacientes',
                        component: () => import('@/views/archivo/ArchivoPacientes.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_PACIENTES_GUARDA', 'ROLE_ADMIN'],
                        },
                    },
                    {
                        path:'pacientes/:id',
                        name: 'archivoPacienteDetalle',
                        component: () => import('@/views/archivo/ArchivoPacienteDetalles.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_PACIENTES_GUARDA', 'ROLE_ADMIN'],
                        },
                    },
                    {
                        path:'egresos',
                        name: 'archivoEgresos',
                        component: () => import('@/views/archivo/ArchivoAltas.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_PACIENTES_GUARDA', 'ROLE_ADMIN', 'ROLE_ADMISION'],
                        },
                    },
                ]
            },
        ],
    },
    {
        path: '/mtto',
        component: () => import('@/views/Main.vue'),
        children:[
            {
                path:'/',
                component: () => import('@/views/mtto/MttoPrincipal'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_MTTO', 'ROLE_PACIENTES', 'ROLE_ADMIN'],
                },
                children:[
                    {
                        path:'/',
                        name: 'mttoSol',
                        component: () => import('@/views/mtto/MttoSolicitudes.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_PACIENTES_GUARDA', 'ROLE_ADMIN'],
                        },
                    },
                ]
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

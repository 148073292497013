export default{
    namespaced: true,

    state:{
        selMedicamentos:[
            {value:1, text:'Paciente equivocado'},
            {value:2, text:'Medicamento incorrecto'},
            {value:3, text:'Error en la dosis'},
            {value:4, text:'Error en la frecuencia de administración'},
            {value:5, text:'Velocidad de administración incorrecta'},
            {value:6, text:'Vía de administración equivocada'},
            {value:7, text:'Error en la administración'},
            {value:8, text:'Contraindicación de la medicación'},
            {value:9, text:'Omisión de la dosis'},
            {value:10, text:'Medicamento caducado'},
            {value:11, text:'Reacción adversa al medicamento'},
            {value:12, text:'Dispensación errónea del medicamento'}
        ],
        selDispositivos:[
            {value:1, text:'Desconexión/mala conexión/retirar'},
            {value:2, text:'Error de usuario'},
            {value:3, text:'Falla/mal funcionamiento'},
            {value:4, text:'Falta de disponibilidad'},
            {value:5, text:'Inapropiado para la indicación médica'},
            {value:6, text:'Inexistente en el hospital'},
            {value:7, text:'Presentación o empaque inadecuado'},
            {value:8, text:'Retiro del dispositivo/equipo no indicado.'},
            {value:9, text:'Sucio/no estéril'}
        ],
        selDocumentos:[],
        selProcMedicos:[],
        selIaas:[],
        selCaidas:[],
        selCaidasTipo:[],
        selPatogenos:[],
        selHemocomponentes:[],
        selGabinete:[],
        selNutricion:[],
    },
    mutations:{
        //SET_LOGIN (state, ld){
        //    state.logedIn = true;
        //    state.username = ld.username;
        //    state.password = ld.password;

        //},
    },
    actions:{},
    getters:{},
}
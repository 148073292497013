import Swal from "sweetalert2"
import 'sweetalert2/dist/sweetalert2.min.css'
export default{
    namespaced: true,

    state:{
        username:'',
        roles:[],
        ventanaLogin:false,
        // El formato es YYYY/MM/DD
        fechaActual:null,
        estaFechaMax:null,
        estaFecha:null,
    },
        
    mutations:{
        CIERRA_SESION(state){
            localStorage.removeItem('username');
            localStorage.removeItem('roles');
            localStorage.removeItem('token');

            state.username = ''
            state.roles = []
        },
        ACTUALIZA_DATOS_USUARIO(state){
            state.username = localStorage.getItem('username')
            if (localStorage.getItem('roles')){
                state.roles = localStorage.getItem('roles').split(',')
            } else {
                state.roles=[]
            }
            
        },
        ABRE_VENTANA_LOGIN(state, d){
            state.ventanaLogin = d
        },

        GUARDA_DATOS_LOGIN(state,resp){
            this.$http.defaults.headers.common['X-Auth-Token']=resp.data.access_token
            
            localStorage.setItem('token',resp.data.access_token)
            localStorage.setItem('roles',resp.data.roles)
            localStorage.setItem('username',resp.data.username)

            state.username = localStorage.getItem('username')

            if (localStorage.getItem('roles')){
                state.roles = localStorage.getItem('roles').split(',')
            } else {
                state.roles=[]
            }

        },
        SET_ESTA_FECHA(state,d){
            if (d){
                let df = d.split('-')
                state.estaFecha = new Date(df[2],df[1]-1,df[0])
            } else {
                state.estaFecha = new Date()
            }
            //this.estaFecha = date.
        },
        SET_ESTA_FECHA_MAX(state){
            // El formato es YYYY/MM/DD
            state.estaFechaMax = new Date().toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'}).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')
        }


    },
    actions:{
        async cargaAntes(context, data){
            let datosLogin={}
            try{
                datosLogin = await this.Axios.post('principal/auth')

                // Si Tiene sesión iniciada
                if (datosLogin.data.loggedIn){
                    // Carga los datos al localstore
                    context.state.username = localStorage.getItem('username')
                    context.state.roles = localStorage.getItem('roles').split(',')
                    context.state.token = localStorage.getItem('token')
                    
                    // Si la ruta requiere login
                    if (data.to.meta.requiresAuth){
                        // Verifica que existan los roles
                        if (localStorage.getItem('roles')){
                            let resp = false
                
                            let rolesTodos = localStorage.getItem('roles').split(',')
                            
                            // Agrega los roles que sean DE CAJÓN
                            data.to.meta.roles.push('ROLE_ADMIN')
                            
        
                            // Recorre los roles para ver si estan incluidos
                            data.to.meta.roles.forEach(role=>{
                                
                                if (rolesTodos.includes(role)){
                                    resp = true
                                }
                            })
                            
                            if (resp){
                                data.next()
                            } else {
                                //alert('No puede acceder a este contenido')
                                Swal.fire({
                                    icon: 'error',
                                    title: 'No se puede acceder',
                                    text: 'No tiene privilegios para acceder.',
                                })
                                data.next('/')
                                
                            }
                        // Si no existen los roles, manda a raiz
                        } else {
                            data.next('/')
                        }
                    // Si no requiere autentificación, deja pasar    
                    } else {
                        data.next()
                    }
                } else {
                    localStorage.removeItem('username');
                    localStorage.removeItem('roles');
                    localStorage.removeItem('token');
                    data.next('/')
                }
            } catch{
                localStorage.removeItem('username');
                localStorage.removeItem('roles');
                localStorage.removeItem('token');
                data.next('/')
            }
        },

        revisaError(context, err){
            /**
            * Esta acción se llama... this.$store.dispatch('security/revisaError',err)
             */
            console.log(err)
            if (err.message == "Network Error"){
                Swal.fire({
                    icon: 'error',
                    title: 'No es posible establecer comunicación con el servidor',
                    text: 'Error de red',
                })
            } else {
                if (err.response.status){
                    switch(err.response.status){
                        
                        case 401:
                            context.state.ventanaLogin = true
                        break

                        case 403:
                            Swal.fire({
                                icon: 'warning',
                                title: 'No tiene privilegios para realizar esta acción ',
                            })
                        break
                        
                        default:
                            Swal.fire({
                                icon: 'error',
                                title: 'No es posible establecer ejecutar la instrucción.',
                                text: 'Error '+err.response.status,
                            })
                        break
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'No es posible establecer ejecutar la instrucción.',
                        text: 'Error '+err,
                    })
                }

                    
            }
        }
    },

    getters:{
        ventanaLogin(state){
            return state.ventanaLogin
        },
        datosUsuario(state){
            return {username:state.username, roles:state.roles}
        },
        isLogedIn(state){
            if (state.username){
                return true
            } else {
                return false
            }
        },
        username(state){
            return state.username
        },
        estaFecha(state){
            // El formato es YYYY/MM/DD
            return state.estaFecha
        },
        estaFechaMax(state){
            // El formato es YYYY/MM/DD
            return state.estaFechaMax
        },
        
    }
}
/*
export default{
    namespaced: true,
    state:{
        logedIn: false,
        username: '',
        password: '',
    },
    mutations:{
        SET_LOGIN (state, ld){
            state.logedIn = true;
            state.username = ld.username;
            state.password = ld.password;

        },
        SET_LOGOUT (state){
            state.logedIn = false
            state.username = ''
            state.password = ''
        },
    },
    actions:{
        logIn(context, parametros){
            context.commit('SET_LOGIN', parametros)
        }
    },
    getters:{
        isLogedIn(state){
            return state.logedIn;
        },
        username(state){
            return state.username;
        },
        
    }
}
*/
//NOE gonzalez morales
//TAC 23-04-2020
//830 y 11


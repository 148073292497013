<template>
        <v-container fluid >
            <v-layout align-center justify-center>

                <v-form
                    ref="formLogin"
                    @submit="doLogin"
                    onSubmit="return false;"
                    v-model="validaLogin"
                >
                    <v-card
                        class="mx-auto"
                        max-width="450"
                        min-width="350"
                        width="350"
                    >

                    <div class="d-flex justify-center pt-5 px-11">
                        <v-img
                            width="100"
                            :src="`images/header-03.png`"
                            >
                        </v-img>
                    </div>
                        <v-card-title>
                            S.E.C.A.H.
                        </v-card-title>
                        <v-card-text>

                            <v-text-field
                                v-model="loginDatos.username"
                                label="Usuario"
                                filled
                                outlined
                                rounded
                                :rules="[v => !!v || 'USUARIO usuario']"
                            ></v-text-field>
                            <v-text-field
                                v-model="loginDatos.password"
                                type="password"
                                label="Contraseña"
                                filled
                                outlined
                                rounded
                                :rules="[v => !!v || 'PASSWORD necesario']"
                            ></v-text-field>

                            <v-expand-transition :v-if="muestraError">
                                <v-alert
                                    color="error"
                                    v-if="muestraError"
                                    dense
                                    text
                                    border="left"
                                >
                                    <span class="caption">
                                        {{mensageLogin}}
                                    </span>
                                </v-alert>
                            </v-expand-transition>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn
                                color="error"
                                text
                                @click="cancelLogin()"
                            >
                                Cancelar
                            </v-btn>

                            <v-btn
                                color="success"
                                :loading="loadingLogin"
                                :disabled="!validaLogin"
                                type='submit'
                            >
                                Iniciar sesión
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-layout>
        </v-container>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'home',

    data: () => ({
        loginDatos:{
            username:'',
            password:'',
        },
        muestraError:false,
        loadingLogin:false,
        mensageLogin:'',
        validaLogin:false,

        //requerido: [
        //    v => !!v || 'Requerido',
        //],
        /*
        valid: false,
        loginData:{
        username:'',
        password:'',
        loginDatos:n
        },
        */
    }),

    methods:{

        async doLogin(){
            this.muestraError=false
            this.loadingLogin=true
            this.mensageLogin = ''

            try {
                let resp = await this.$http({
                    url: 'login',
                    method: 'POST',
                    data: this.loginDatos,
                })

                this.$refs.formLogin.reset()
                this.$store.commit('security/GUARDA_DATOS_LOGIN', resp);
                this.$router.push({path:'/dashboard'})

                this.loadingLogin=false


            }catch(err) {
                this.loadingLogin=false
                this.muestraError=true
                console.log(err)

                if (err.message == "Network Error"){
                    this.mensageLogin = 'No es posible establecer comunicación con el servidor'

                } else {
                    switch(err.response.status){

                        case 401:
                            //context.state.ventanaLogin = true
                            this.mensageLogin = 'Nombre de usuario o contraseñas incorrectas'
                        break

                        case 403:
                            this.mensageLogin = 'No tiene los permiso para esta acción'

                        break

                        default:
                            this.mensageLogin = 'No es posible establecer ejecutar la instrucción. Error '+err.response.status

                        break
                    }

                }
            }
        },
        cancelLogin(){
            this.mensageLogin = ''
            this.muestraError=false
            this.$refs.formLogin.reset()
            this.loginDialog= false
        },
    },
    mounted: function() {
    }
}
</script>
<style >

</style>
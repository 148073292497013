import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from 'axios'

import VueHtmlToPaper from 'vue-html-to-paper-pro'

import moment from 'moment'

import numeral from 'numeral'
import numFormat from 'vue-filter-number-format'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.use(VueHtmlToPaper)


Vue.prototype.$numeral = numeral
Vue.prototype.$swal = Swal

if (process.env.NODE_ENV == 'development'){
    //Vue.prototype.$baseUrl = 'http://172.16.1.36:9999/secah2015/'
    //Axios.defaults.baseURL = 'http://172.16.1.36:9999/secah2015/api'

    Vue.prototype.$baseUrl = 'http://172.16.1.36:9999/secah2015/'
    Axios.defaults.baseURL = 'http://172.16.1.36:9999/secah2015/api'
} else {
    Vue.prototype.$baseUrl = 'https://www.hno.oaxaca.gob.mx/secah/'
    Axios.defaults.baseURL = 'https://www.hno.oaxaca.gob.mx/secah/api'
}

Axios.defaults.timeout = 120000

Vue.prototype.$http = Axios
moment.locale('es_MX')
Vue.prototype.moment = moment


if (localStorage.getItem('token')){
    Vue.prototype.$http.defaults.headers.common['X-Auth-Token']=localStorage.getItem('token')
}


Vue.filter('numFormat', numFormat(numeral));
Vue.config.productionTip = false

// Esto es para que funciones el this.http en los módulos del store
store.$http = Vue.prototype.$http
store.Axios = Axios

store.moment = moment
router.beforeEach((to, from, next) => {

    //Se carga en este punto la actualización de la fecha para los date-picker (en el store de security)
    store.commit('security/SET_ESTA_FECHA_MAX')

    if (to.name == 'home'){
        next()
    } else {
        store.dispatch('security/cargaAntes', {to:to, from:from, next:next})
    }
    //next()
})


new Vue({
    router,
    store,
    vuetify,
    //VueSweetalert2,
    Swal,

    render: h => h(App)
}).$mount('#app')

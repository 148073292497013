//import Swal from "sweetalert2"
import 'sweetalert2/dist/sweetalert2.min.css'
export default{
    namespaced:true,

    state:{
        servicios:[],
        areas:[],
        tipoAlta:[],

        especialidades:[],

        tiposReferencias:[
            {value:1, text:'Espacio físico'},
            {value:2, text:'Consulta'},
            {value:99, text:'Otro'},
        ],
        estadosReferencias:[
            {value:1, text:'Solicitado', color:'blue'},
            {value:2, text:'En revisión', color:'orange'},
            {value:3, text:'Aceptado', color:'green'},
            //{value:4, text:'Referido/Contra referido', color:'purple'},
            {value:98, text:'No aceptado', color:'red'},
            {value:99, text:'Cancelado', color:'grey'},
        ],
        
    },

    mutations:{
        
    },

    actions:{
        

        // Para el select de servicio
        async cargaArea(context){
            let sr = await this.$http({
                url:'/catalogos/areas',
                method:'GET',
            })
            context.state.areas = sr.data
        },

        // Para el select de servicio
        async cargaServicio(context){
            let sr = await this.$http({
                url:'/catalogos/servicios',
                method:'GET',
            })
            context.state.servicios = sr.data
        },
        
        // Para el select de servicio
        async cargaEspecialidad(context){
            let sr = await this.$http({
                url:'/catalogos/especialidadesListTodas',
                method:'GET',
            })
            context.state.especialidades = sr.data
        },

        // Para el select de los tipos de alta
        async tipoAlta(context){
            let sr = await this.$http({
                url:'/catalogos/tiposaltas',
                method:'GET',
            })
            context.state.tipoAlta = sr.data
        },
    },
}